import React from 'react';
import styled from 'react-emotion';
import { colors } from "../utils/presets"


const listStyle = {
  listStyle: 'none',
  margin: '0 0 30px 0',
  borderBottom: '1px solid gray',
  borderTop: '1px solid gray',
  padding: '20px 0 15px',
};

const RSVP = styled(`a`)`
  background-color: ${colors.lilac};
  border-radius: 4px;
  font-size: 0.8rem;
  padding: 6px 8px;
  color: white;
  &:hover {
    color: white;
    background-color: ${colors.gatsby};
  }
`;

export default () => (
  <ul css={listStyle}>
    <li><strong>Date:</strong> Sunday March 3, 2019</li>
    <li><strong>Time:</strong> 10:00am - 1:00pm </li>
    <li><strong>Location:</strong> <a href="https://www.sahajaspirit.com/sahaja-space" target="_blank">SAHAJA SPACE</a>
      <br />
      <a href="https://goo.gl/maps/EYUnomQAaD12" target="_blank">125 E Parrish St, Durham, NC 27701</a></li>
    <li css={{ marginBottom: `20px` }}><strong>Cost:</strong> $16-$36 – sliding scale, pay what you can afford | tea and light refreshments provided</li>
    <li>
      <RSVP
        href="https://clients.mindbodyonline.com/classic/ws?studioid=513281&stype=-8&sView=day&sLoc=0&date=03/03/19"
        target="_blank">
        RSVP
      </RSVP>
    </li>
  </ul>
)