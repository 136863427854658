import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import { graphql } from 'gatsby';
import { colors } from "../utils/presets";
import WorkshopInfo from '../components/workshopInfo';
import WorkshopHeader from '../components/workshopHeader';
export const pageQuery = graphql`
query Workshop {
    allSitePage {
      edges {
        node {
          path
        }
      }
    }
  }
`;
const layoutProps = {
  pageQuery
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);

    this.layout = ({
      children,
      ...props
    }) => <div css={`margin-top: 3rem;`}>
    {children}
  </div>;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>


      <WorkshopHeader />
      <MDXTag name="p" components={components}>{`In this workshop you will have the opportunity to learn about the robust history of the
Book of Changes and gain a solid understanding of the practice of how to consult
the I Ching with your questions or issues. This valuable text and inquiry method
serves as a beautiful compliment to a meditation/yoga practice, or simply provides
positive wisdom and support through the changes of life. No prior experience required. `}</MDXTag>
      <MDXTag name="p" components={components}>{`Join Toviah Narva and Nicole Asselin, I Ching practitioners, at the beautiful,
sun-filled Sahaja Space for an afternoon of inquiry, self-reflection and insight.`}</MDXTag>
      <WorkshopInfo />
      <MDXTag name="p" components={components}>{`This workshop is part of a series of events and happenings informing the content for the forthcoming publication of
Toviah and Nicole’s book and app: Sixty Four Changes. Thank you for being a part of the creation process.
Your voice and input are appreciated, please contact us with any reflections, feedback or suggestions. `}</MDXTag>
      <MDXTag name="p" components={components}>{`Email: info@sixtyfourchanges.com`}<br />{`
Instagram: `}<a href="https://www.instagram.com/sixtyfourchanges/" target="_blank">{`sixtyfourchanges`}</a></MDXTag>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {
  "title": "Workshop"
};
    